<template>
  <div class="flex flex-col flex-1 overflow-hidden bg-white border border-gray-300 rounded">
  
    <POrderGrid  
      ref="ordergrid"
      transactionType="GL,OO"
      :locationIdFrom="location.id"
      :cols="cols"
      :filters="filters"
      :orderBy="orderBy"
      :expandAll="expandAll"      
      @click="onClick"
    ></POrderGrid>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <portal to="actions">
      <div class="flex flex-col items-center space-x-2 md:flex-row space-y-2 md:space-y-0">

        <PToggle 
          v-model="expandAll" 
          :stacked="true" 
          :label="$tk('Common.General.Detailed')" 
        />

        <PButton 
          @click="importDialogVisible = true" 
          color="secondary" 
          icon="file-excel"          
        >
          <span v-html="$tk('Common.General.Import')"></span>
        </PButton>

        <PButton 
          v-hint="{ show: hintsVisible, text: 'K' }"          
          v-shortkey.push="['alt', 'k']"          
          @shortkey.native="onSendDraftsShow"
          @click="onSendDraftsShow"
          color="secondary"          
        >
          <span v-html="$tk('Delivery.SendDrafts')"></span>
        </PButton>

        <PButton           
          v-hint="{ show: hintsVisible, text: 'N' }"          
          v-shortkey.push="['alt', 'n']"          
          @shortkey.native="onCreateShow"
          @click="onCreateShow"
          color="primary"          
        >
          <span v-html="$tk('Delivery.NewDelivery')"></span>
        </PButton>

      </div>
    </portal>

    <OrderDialog 
      v-if="createDialogVisible" 
      transactionType="GL"
      :orderId="orderId"
      @close="onCreateClose" 
    />

    <EditDialog 
      v-if="editDialogVisible"
      :showSorts="false"
      :showWaybill="false"
      :orderId="orderId"
      :tab="editDialogStartTab"
      @close="onEditClose"
      view="delivery"
    />

    <ImportDialog 
      v-if="importDialogVisible" 
      @close="onImportClose" 
    />

    <SendDraftsDialog 
      v-if="sendDraftsDialogVisible" 
      @close="onSendDraftsDialogClose" 
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex"

import { format, add, sub } from "date-fns"

import { some } from "lodash"

import OrderDialog from "@/areas/orders/dialogs/OrderDialog.vue"
import EditDialog from "../../reception/dialogs/EditDialog.vue"
import ImportDialog from "@/areas/orders/dialogs/ImportDialog.vue"
import SendDraftsDialog from "../dialogs/SendDraftsDialog.vue"

export default {
  components: {
    OrderDialog,
    EditDialog,
    ImportDialog,
    SendDraftsDialog
  },
  data() {
    return {
      cols: [        
        { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, xalign: "center", classes: "font-medium" },
        { text: this.$tk("Common.Order.Sent"), value: "dateSend", sortable: true, component: "p-display-date", width: 110, align: "center" },
        { text: this.$tk("Common.Order.To"), sortable: true, component: "p-display-location", config: { direction: "To" }, align: "left" },
        { text: this.$tk("Common.Order.SendersRef"), value: "sendersRef", sortable: true, component: "p-display-text" },
        { text: this.$tk("Common.Order.ReceiversRef"), value: "receiversRef", sortable: true, component: "p-display-text" },
        { 
          nested: [            
            { text: this.$tk("Common.Order.RTI"), component: "p-display-product", aggregate: "p-display-product-images", config: { showPoolingQuality: false }, align: "left", width: 220 },            
            { text: this.$tk("Common.Order.Sent"), value: "quantityOrdered", component: "p-display-number", aggregate: "p-display-sum", width: 120, align: "right" },             
            { text: this.$tk("Common.Order.Received"), value: "quantityConfirmed", component: "p-display-number", aggregate: "p-display-sum", width: 120, align: "right" },             
          ] 
        },        
        { text: this.$tk("Common.Order.Comments"), value: "commentCount", component: "p-display-comments", width: 50, align: "center" },
        { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center" },
      ],      
      orderId: null,      
      expandAll: true,
      filters: {
        term:             { value: "" },
        sendLower:        { value: format(sub(new Date(), { months: 1 }), "yyyy-MM-dd") },
        sendUpper:        { value: format(add(new Date(), { days: 1 }), "yyyy-MM-dd") },
        statuses: [
          { ids: [0, 1], value: true, label: this.$tk("Common.OrderStatus.Draft") },
          { id: 4, value: true },
          { id: 6, value: true },
          { id: 7, value: false },
          { id: 5, value: false, label: this.$tk("Common.OrderStatus.Received") },
        ]   
      },    
      orderBy: {
        field: "dateSend",
        descending: false
      },     
      sendDraftsDialogVisible: false,
      createDialogVisible: false,
      editDialogVisible: false,
      editDialogStartTab: "",
      importDialogVisible: false,
    }
  },  

  computed: {
    ...mapGetters([
      "location",
      "hintsVisible"
    ])
  },

  methods: {

    getOrders () {
      this.$refs.ordergrid.getOrders()     
    },

    onCreateShow () {
      this.createDialogVisible = true
    },

    onSendDraftsShow () {
      this.sendDraftsDialogVisible = true
    },

    onClick ({ order, component }) {
      
      if (order.interpoolingCompany) return
            
      this.orderId = order.id

      if (some([0, 1], id => id === order.transactionStatusId)) {
        this.createDialogVisible = true
      } else {

        this.editDialogStartTab = component === "p-display-comments" ? "comments" : ""
        this.editDialogVisible = true
      }
    },
    
    onCreateClose (createNew = false) {
      this.createDialogVisible = false
      this.orderId = null
      this.getOrders()
      if (createNew) {
        this.$nextTick(() => {
          this.createDialogVisible = true
        })
      }
    },

    onEditClose () {
      this.editDialogVisible = false
      this.orderId = null
      this.getOrders()
    },

    onImportClose () {
      this.importDialogVisible = false
      this.getOrders()
    },

    onSendDraftsDialogClose () {
      this.sendDraftsDialogVisible = false
      this.getOrders()
    }

  }

}
</script>
